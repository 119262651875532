//上传富文本
var COS = require('cos-js-sdk-v5');
import {getCosCredit} from '../apis/index'
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});

function uploadRichText(selectFileobj,callback) {
    let randomNum=Math.random().toString().slice(2)
    let filename ='content/resource/richText/'+randomNum+new Date().getTime()
    cos.options.getAuthorization('', function (obj) {
      cos.putObject({
        Bucket: sessionStorage.getItem('bucket'), /* 必须 */
        Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
        Key: filename,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: selectFileobj, // 上传文件对象
        onProgress: function (progressData) {
          // console.log(JSON.stringify(progressData));
          console.log(progressData)
        }
      }, function (err, data) {
        console.log(err || data);
        if (data) {
          // console.log(data)
          
            // https://sihuankangyuan.cn
          //  let richTextUri= data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
        //    return img
        // let result='http://content-1308336073.cos.ap-nanjing.myqcloud.com/'+filename
        let result=filename
        callback(result)
        }
      });
    })
  }

  export default uploadRichText