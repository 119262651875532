//上传图片
var COS = require('cos-js-sdk-v5');
import {getCosCreditUser} from '../apis/index'
import { Loading  } from 'element-ui'
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCreditUser().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});

function uploadImg(selectFileobj,callback) {
    let name = selectFileobj.name
    let filename = 'user/resource/image/' +
      name.slice(0, name.length - 4) +
      new Date().getTime() +
      name.slice(-4);
    // let name = selectFileobj.name
    // let filename = 'user/resource/image/' +
    //   new Date().getTime() +randomString(6)
      var loading
    cos.options.getAuthorization('', function (obj) {
      cos.putObject({
        Bucket: sessionStorage.getItem('bucket'), /* 必须 */
        Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
        Key: filename,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: selectFileobj, // 上传文件对象
        onProgress: function (progressData) {
          // console.log(JSON.stringify(progressData));
          loading = Loading.service({ fullscreen: true,text:'资源上传中~',background:'rgba(0,0,0,0.5)'});
          console.log(progressData)
        }
      }, function (err, data) {
        console.log(err || data);
        if (data) {
          //  let img= data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
        //    return img
        let img='https://'+ data.Location
        // let img=data.Location
        loading.close();
        callback(img)
        }
      });
    })
  }

//    function randomString(len) {//随机字符串
//     len = len || 32;
//     var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
//     var maxPos = $chars.length;
//     var pwd = '';
//     for (let i = 0; i < len; i++) {
//         pwd += $chars.charAt(Math.floor(Math.random() * maxPos));

//     }
//     return pwd;

// }

  export default uploadImg