//插入图片至富文本
var COS = require('cos-js-sdk-v5');
import {getCosCredit} from '../apis/index'
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});

function uploadRIchImg(selectFileobj,quill) {
    let name = selectFileobj.name
    let filename = 'content/resource/newManage/image/' +
      name.slice(0, name.length - 4) +
      new Date().getTime() +
      name.slice(-4);
    cos.options.getAuthorization('', function (obj) {
      cos.putObject({
        Bucket: sessionStorage.getItem('bucket'), /* 必须 */
        Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
        Key: filename,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: selectFileobj, // 上传文件对象
        onProgress: function (progressData) {
          // console.log(JSON.stringify(progressData));
          console.log(progressData)
        }
      }, function (err, data) {
        console.log(err || data);
        if (data) {
          let img='https://'+ data.Location
          //  let img= data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
            //    return img
            //  let quill = that.$refs.richAnalysis.quill
             let length = quill.getSelection().index;
            // 插入图片，url为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', img)
            // 调整光标到最后
            quill.setSelection(length + 1)

        }
      });
    })
  }

  

  export default uploadRIchImg